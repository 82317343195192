import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Accounts from "../components/screens/accounts/Accounts";
import Acknowledgement from "../components/screens/acknowledgement/Acknowledgement";
import AttachmentType from "../components/screens/attachment_type/AttachmentType";
import Categories from "../components/screens/categories/Categories";
import Clients from "../components/screens/clients/Clients";
import Contractors from "../components/screens/contractors/Contractors";
import Login from "../components/screens/login/Login";
import Projects from "../components/screens/projects/Projects";
import Roles from "../components/screens/roles/Roles";
import Status from "../components/screens/status/Status";
import Dashboard from "../components/screens/Dashboard";
import ProtectedRoute from "./ProtectedRoutes";

export const ApplicationRoutes = () => {
  return (
    <Routes>
      {/* Public route: Login */}
      <Route path="/login" element={<Login />} />

      {/* Secured routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          <Route element={<Dashboard />} />
          {/* Config related routes */}
          <Route path="config/accounts" element={<Accounts />} />
          <Route path="config/clients" element={<Clients />} />
          <Route path="config/contractors" element={<Contractors />} />
          <Route path="config/categories" element={<Categories />} />
          <Route path="config/status" element={<Status />} />
          <Route path="config/roles" element={<Roles />} />
          <Route path="config/acknowledgement" element={<Acknowledgement />} />
          <Route path="config/projects" element={<Projects />} />
          <Route path="config/attachment-type" element={<AttachmentType />} />
        </Route>
      </Route>
    </Routes>
  );
};
