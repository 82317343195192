import axios from "axios";

const BASE_URL = "https://wizzio-api.wegivethebest.com/api";

// const BASE_URL = "http://localhost:8000/api";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // withCredentials: true,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token"); // Or use localStorage if needed
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
