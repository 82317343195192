import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { Box, Typography } from "@mui/material";
import React from "react";

function ClientsRecentChanges({ item, index }) {
  return (
    <Box
      key={index}
      style={{
        background: "#FFFFFF",
        padding: 16,
        borderRadius: 8,
        marginBottom: 8,
      }}
    >
      <Box display="flex" alignItems="center" style={{ marginBottom: "4px" }}>
        <Typography style={{ padding: 0, margin: 0, listStyleType: "disc" }}>
          <li
            style={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            S.No
          </li>
        </Typography>
        <Typography
          style={{
            whiteSpace: "nowrap",
            marginLeft: 24,
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {item}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" style={{ marginBottom: "4px" }}>
        <Typography style={{ padding: 0, margin: 0, listStyleType: "disc" }}>
          <li
            style={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Account Name
          </li>
        </Typography>
        <Typography
          style={{
            whiteSpace: "nowrap",
            marginLeft: 24,
            fontSize: 14,
            fontWeight: 400,
          }}
        >{`Account ${item}`}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "14px", fontWeight: "700", mb: 2 }}>
          Change Made
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              backgroundColor: "#D3F3D4",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            Active
          </Typography>
          <EastOutlinedIcon sx={{ width: 16, fontWeight: 600 }} />
          <Typography
            style={{
              background: "#F9B5B5",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            Inactive
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ClientsRecentChanges;
