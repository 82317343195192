import { Visibility, VisibilityOff } from "@mui/icons-material";
import Mail from "@mui/icons-material/Mail";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  styled,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import whiteImage from "../../../assets/White.png";
import axios from "axios";
import axiosInstance from "../../../api/axios";
import { Navigate, useNavigate } from "react-router-dom";
import { LOGIN_SCHEMA } from "../../../utils/schema";
import { yupResolver } from "@hookform/resolvers/yup";

const Login = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const [showPassword, setShowPassword] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
      stayLoggedIn: false,
    },
    resolver: yupResolver(LOGIN_SCHEMA),
  });
  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Prevent default action when mouse is down
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Handle form submission
  const handleLogin = async (data) => {
    const { username, password } = data;

    try {
      const payload = {
        user_name: username,
        password: password,
      };
      const response = await axiosInstance.post("/loginUser", payload);
      if (!response?.data?.error) {
        const token = response.data?.data?.token;
        sessionStorage.setItem("token", token);
        navigate("/");
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Invalid username or password");
    }
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#F3F6F9",
      border: "1px solid",
      borderColor: "#E0E3E7",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
        borderColor: "#2D3843",
      }),
    },
  }));
  return (
    <Grid container className="login-container">
      <Grid
        size={{ xs: 12, md: 6 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box component="img" src={whiteImage} alt="White Logo" sx={{ m: 5 }} />
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Box
            component="form"
            onSubmit={handleSubmit(handleLogin)}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "var(--font-alternative)",
                fontSize: "16px",
                fontWeight: 400,
                color: "#50A5E0",
                marginBottom: 0,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              Login
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "var(--font-alternative)",
                fontSize: "24px",
                fontWeight: 600,
                color: "#1A2122",
                marginBottom: 2,
                lineHeight: "45px",
                textAlign: "left",
                mb: 0,
              }}
            >
              Welcome to Wizzio
            </Typography>
            <Controller
              name="username"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <FormControl
                    variant="standard"
                    className="custom-form-control"
                  >
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className="text-input-label"
                    >
                      Username
                    </InputLabel>
                    <BootstrapInput
                      defaultValue={value}
                      id="bootstrap-input"
                      fullWidth
                      // size="small"
                      helperText={error?.message}
                      type={"text"}
                      placeholder={"Enter your username"}
                      variant="outlined"
                      onChange={onChange}
                      error={true}
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mail />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {error?.message || ""}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              name="password"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <FormControl
                    variant="standard"
                    className="custom-form-control"
                  >
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className="text-input-label"
                    >
                      Password
                    </InputLabel>
                    <BootstrapInput
                      defaultValue={value}
                      id="bootstrap-input"
                      fullWidth
                      type="password"
                      // size="small"
                      // helperText={errors?.[name]?.message}
                      placeholder={"Enter your password"}
                      variant="outlined"
                      onChange={onChange}
                      error={error?.message ? true : false}
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mail />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {error?.message || ""}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              name="stayLoggedIn"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{
                        color: "#64748B",
                        "&.Mui-checked": {
                          color: "#64748B",
                        },
                      }}
                    />
                  }
                  className="text-input-label"
                  label="Stay Logged In"
                />
              )}
            />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginTop: "48px",
                  width: "100%",
                  height: "48px",
                  borderRadius: "8px",
                  justifyContent: "center",
                  padding: "0px 16px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  backgroundColor: "#47A0DE",
                  "&:hover": {
                    backgroundColor: "#47A0DE",
                  },
                }}
              >
                Log in
              </Button>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 300,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#64748B",
                  mt: 1,
                }}
              >
                If you are facing any issues, contact{" "}
                <a
                  href="#"
                  style={{
                    color: "#089BA6",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  Support
                </a>
              </Box>
            </div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
