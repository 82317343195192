import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { ACCOUNT_SCHEMA, CATEGORIES_SCHEMA } from "../../../utils/schema";
import { FormInputText } from "../../form-components/FormInputText";
import GenerateDataTable from "../../reusable/GenerateDataTable";
import CategoriesRecentChanges from "./CategoriesRecentChanges";
import FormMultiSelectDropdown from "../../form-components/FormMultiSelectDropdown";
import SearchBar from "../../reusable/SearchBar";

function Categories() {
  const methods = useForm({ resolver: yupResolver(CATEGORIES_SCHEMA) });
  const apiRef = useGridApiRef();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [attachmentList, setAttachmentList] = useState([]);

  const indexCategory = () => {
    setLoading(true);
    setSelectedRow(null);
    reset();
    axiosInstance
      .get(`/indexCategories`)
      .then((response) => {
        if (response.data.error === false) {
          const formattedData = response.data.data.map((item, index) => ({
            index,
            ...item,
          }));
          setRows(formattedData);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getActiveAttachmentType = () => {
    setLoading(true);
    setSelectedRow(null);
    reset();
    axiosInstance
      .get(`/getActiveAttachmentType`)
      .then((response) => {
        if (response.data.data?.length > 0) {
          setAttachmentList(response.data?.data);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    indexCategory();
    getActiveAttachmentType();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      handleOpen();
      setSelectedRow(currentRow);
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/categoriesSwitch/${currentRow?.categories_id}`,
        {
          status: newStatus,
        }
      );

      if (response.data.error) {
        console.error(response.data.message);
        toast.error(response.data.message);
      } else {
        indexCategory();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };

  const handleFormSubmit = async (data) => {
    try {
      let response;

      const uniqueclientIdsArray = [...new Set(data?.attachmenttype_id)];
      if (selectedRow?.categories_id) {
        const params = {
          categories_name: data.categories_name,
          active_status: selectedRow?.active_status,
          categories_id: selectedRow?.categories_id,
          attachmenttype_id: uniqueclientIdsArray,
        };
        response = await axiosInstance.post("/updateCategories", params);
      } else {
        const params = {
          categories_name: data.categories_name,
          active_status: 0,
          attachmenttype_id: uniqueclientIdsArray,
        };
        response = await axiosInstance.post("/categories", params);
      }

      if (response?.data?.error === false) {
        toast.success(response?.data?.message);
        indexCategory();
        handleClose();
      } else {
        toast.error("ERROR:" + response?.data?.message);
        console.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error creating account:", error);
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
    },
    {
      field: "categories_name",
      headerName: "Categories Name",
      flex: 1,
    },
    {
      field: "attachmenttype_id",
      headerName: "Attatchment Type",
      flex: 1,
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <Select
          value={row.active_status}
          onChange={(e) => handleChangeStatus(e, row)}
          displayEmpty
          style={{
            minWidth: "150px",
            maxWidth: "200px",
            borderRadius: "5px",
            background: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
            opacity: 1,
            fontSize: 16,
            fontWeight: 400,
            border: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="1">Active</MenuItem>
          <MenuItem value="0">Inactive</MenuItem>
          {row.active_status == "0" && <MenuItem value="EDIT">Edit</MenuItem>}
        </Select>
      ),
    },
  ];

  const RecentChangeContainer = () => (
    <Paper
      style={{
        padding: "16px",
        borderRadius: "5px",
        background: "#F6F6F6",
        overflow: "auto",
      }}
    >
      <Typography
        variant="h6"
        style={{
          fontWeight: 600,
          fontSize: "16px",
          fontFamily: "Inter",
          lineHeight: "19.36px",
          margin: "0px 0px 8px 0px",
          color: "#000000",
        }}
      >
        Recent Changes
      </Typography>
      <Typography
        variant="body2"
        style={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "16.94px",
          // margin: '0px 0px 16px 0px',
          color: "#636363",
        }}
      >
        View all the recent changes made from your end and keep tabs.
      </Typography>
      <Box sx={{ mt: 4 }}>
        {[1, 2, 3].map((item, index) => (
          <CategoriesRecentChanges item={item} />
        ))}
      </Box>
    </Paper>
  );
  const Modal = () => (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: "0px",
          borderRadius: "5px 0 0 0",
          backgroundColor: "#F6F6F6",
          opacity: 1,
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "16px 24px",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "22px",
              fontWeight: 700,
              color: "#272625",
            }}
          >
            {selectedRow?.categories_id
              ? "Edit Categories"
              : "Create Categories"}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              width: "24px",
              height: "24px",
              border: "1px solid #FF3B30",
            }}
          >
            <CloseIcon sx={{ color: "#FF3B30" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
        <Grid container columnSpacing={{ xs: 5 }} sx={{ gap: 2 }}>
          <Grid size={12}>
            <FormInputText
              name="categories_name"
              control={control}
              label="Categories Name"
              errors={errors}
              mandatory="true"
              placeholder={"Enter Categories Name"}
              defaultValue={selectedRow?.categories_name}
            />
          </Grid>
          <Grid size={12}>
            <FormMultiSelectDropdown
              name="attachmenttype_id"
              control={control}
              label={"Attatchment Type"}
              placeholder={"Enter Attatchment Type"}
              options={attachmentList?.map((item) => {
                return {
                  key: item?.attachmenttype_name,
                  label: item?.attachmenttype_name,
                };
              })}
              defaultValue={selectedRow?.attachmenttype_id
                ?.split(",")
                ?.map((item) => item)}
              mandatory="true"
              setValue={setValue}
            />
          </Grid>
          {/* <Grid size={6}>
            <FormInputDropdown
              name="active_status"
              control={control}
              label={selectedRow?.active_status}
              type="single"
              options={STATUS}
              errors={errors}
              defaultValue={selectedRow?.active_status}
              mandatory="true"
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          // disabled={!isValid}
          color="primary"
          variant="contained"
          onClick={handleSubmit(handleFormSubmit)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={9}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
              }}
            >
              Categories
            </Typography>
            <SearchBar
              setLoading={setLoading}
              setRows={setRows}
              loadInitialData={indexCategory}
              searchAPI={"searchCategories"}
            />
          </Box>
          <GenerateDataTable
            rows={rows || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.categories_id}
          />
        </Grid>
        <Grid
          size={3}
          sx={{ gap: "16px", display: "flex", flexDirection: "column" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{
              height: "40px",
              padding: "8px, 16px, 8px, 16px",
              borderRadius: "8px",
              backgroundColor: "#47A0DE",
              color: "#FFFFFF",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              gap: "10px",
            }}
          >
            + New Record
          </Button>
          <RecentChangeContainer />
        </Grid>
      </Grid>
      <Modal />
    </>
  );
}

export default Categories;
