import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactComponent as DashboardIcon } from "../../assets/icons/home.svg";
import { ReactComponent as RevenueIcon } from "../../assets/icons/revenue.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notification.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/icons/analytics.svg";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { styled, useTheme } from "@mui/material/styles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIconChildren from "../../utils/SvgIconChildren";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BreadcrumbsComponent from "./Breadcrumbs";

const drawerWidth = 266;

const mainMenu = [
  {
    key: "config",
    text: "Config",
    icon: SettingsOutlinedIcon,
    subMenu: true,
    open: false,
    items: [
      {
        key: "accounts",
        text: "Accounts",
        icon: AccountCircleOutlinedIcon,
        route: "/accounts",
      },
      {
        key: "clients",
        text: "Clients",
        icon: Diversity1OutlinedIcon,
        route: "/clients",
      },
      {
        key: "contractors",
        text: "Contractors",
        icon: DescriptionOutlinedIcon,
        route: "/contractors",
      },
      {
        key: "categories",
        text: "Categories",
        icon: CategoryOutlinedIcon,
        route: "/categories",
      },
      {
        key: "status",
        text: "Status",
        icon: QueryStatsOutlinedIcon,
        route: "/status",
      },
      {
        key: "roles",
        text: "Roles",
        icon: SupervisorAccountOutlinedIcon,
        route: "/roles",
      },
      {
        key: "acknowledgement",
        text: "Acknowledgement",
        icon: HandshakeOutlinedIcon,
        route: "/acknowledgement",
      },
      {
        key: "projects",
        text: "Projects",
        icon: InventoryOutlinedIcon,
        route: "/projects",
      },
      {
        key: "attachment-type",
        text: "Attachment Type",
        icon: AttachFileOutlinedIcon,
        route: "/attachment-type",
      },
    ],
  },
];
// const mainMenu = [
//   {
//     key: "accounts",
//     text: "Accounts",
//     icon: AccountCircleOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/accounts",
//   },
//   {
//     key: "clients",
//     text: "Clients",
//     icon: Diversity1OutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/clients",
//   },
//   {
//     key: "contractors",
//     text: "Contractors",
//     icon: DescriptionOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/contractors",
//   },
//   {
//     key: "categories",
//     text: "Categories",
//     icon: CategoryOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/categories",
//   },
//   {
//     key: "status",
//     text: "Status",
//     icon: QueryStatsOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/status",
//   },
//   {
//     key: "roles",
//     text: "Roles",
//     icon: SupervisorAccountOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/roles",
//   },
//   {
//     key: "acknowledgement",
//     text: "Acknowledgement",
//     icon: HandshakeOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/acknowledgement",
//   },
//   {
//     key: "projects",
//     text: "Projects",
//     icon: InventoryOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/projects",
//   },
//   {
//     key: "attachment-type",
//     text: "Attachment Type",
//     icon: AttachFileOutlinedIcon,
//     subMenu: false,
//     open: false,
//     route: "/attachment-type",
//   },
//   // {
//   //   key: "inventory",
//   //   text: "Inventory",
//   //   icon: HandshakeOutlinedIcon,
//   //   subMenu: true,
//   //   open: false,
//   //   items: [
//   //     {
//   //       key: "computer",
//   //       text: "Computer",
//   //       route: "/computer",
//   //       icon: InventoryIcon,
//   //     },
//   //     {
//   //       key: "laptops",
//   //       text: "Laptops",
//   //       route: "/laptops",
//   //       icon: InventoryIcon,
//   //     },
//   //     {
//   //       key: "printers",
//   //       text: "Printers",
//   //       route: "/printers",
//   //       icon: InventoryIcon,
//   //     },
//   //   ],
//   // },
// ];
function Layout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation(); // To get the current route
  const currentRoute = location.pathname;
  const [open, setOpen] = React.useState(true);
  const [menuItems, setMenuItems] = React.useState(mainMenu);

  console.log(currentRoute);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleMenuClick = (index) => {
    const updatedMenuItems = menuItems.map((item, i) => {
      if (i === index) {
        return { ...item, open: !item.open };
      } else {
        return { ...item, open: false };
      }
    });
    setMenuItems(updatedMenuItems);
  };

  const SearchInput = () => {
    return (
      <div className="search-container">
        <SearchIcon className="search-icon" />
        <input type="text" placeholder="Search..." className="search-input" />
      </div>
    );
  };
  const DrawerHeaderWithLogo = () => {
    return (
      <>
        <div className="side-nav-header">
          <Avatar
            src={require("../../assets/logo.png")}
            alt="Logo"
            className="logo"
          />
          <div className="text-container">
            <div className="name">SALMAN KHAN</div>
            <div className="email">salman@demo.com</div>
          </div>
        </div>
        <SearchInput />
      </>
    );
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    flexDirection: "column",
  }));
  const drawer = (
    <div className="drawer-container">
      <div>
        <DrawerHeader>
          <DrawerHeaderWithLogo />
        </DrawerHeader>
        <div style={{ gap: 18, display: "flex", flexDirection: "column" }}>
          <List className="side-nav-bar-menu">
            {menuItems.map((item, index) => (
              <React.Fragment key={item.key}>
                {!item.subMenu ? (
                  <ListItem
                    button
                    onClick={() => navigate(item?.route)}
                    sx={{
                      backgroundColor:
                        currentRoute === item?.route
                          ? "#47A0DE !important"
                          : "transparent",
                    }}
                  >
                    <ListItemIcon>
                      <item.icon
                        sx={{
                          color:
                            currentRoute === item?.route
                              ? "#FFFFFF"
                              : " #09090A",
                        }}
                      />
                      {/* </SvgIconChildren> */}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        color:
                          currentRoute === item.route ? "#FFFFFF" : "#09090A",
                      }}
                    />
                  </ListItem>
                ) : (
                  <>
                    <ListItemButton
                      onClick={() => handleMenuClick(index)}
                      sx={{
                        backgroundColor:
                          currentRoute === item?.route
                            ? "#47A0DE !important"
                            : "transparent",
                      }}
                    >
                      <ListItemIcon>
                        {item.open ? (
                          <ExpandMoreIcon />
                        ) : (
                          <item.icon sx={{ color: "grey" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`${item.text}`} />
                    </ListItemButton>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.items.map((subItem) => (
                          <ListItem
                            sx={{
                              px: open ? "32px" : "16px",
                              bgcolor:
                                currentRoute ===
                                `/${item?.key}${subItem?.route}`
                                  ? "#47A0DE !important"
                                  : "transparent",
                            }}
                            key={subItem.key}
                            button
                            onClick={() =>
                              navigate(`${item?.key}${subItem?.route}`)
                            }
                            // sx={{ pl: 4 }}
                          >
                            <ListItemIcon>
                              <subItem.icon
                                sx={{
                                  ml: 2,
                                  color:
                                    currentRoute ===
                                    `/${item?.key}${subItem?.route}`
                                      ? "#FFFFFF"
                                      : " #09090A",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.text}
                              primaryTypographyProps={{
                                sx: {
                                  color:
                                    currentRoute ===
                                    `/${item?.key}${subItem?.route}`
                                      ? "#FFFFFF !important"
                                      : " #09090A !important",
                                },
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
      </div>

      <List>
        {["Logout"].map((text, index) => (
          <>
            <Divider />
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: "#FF3B30" }} />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} className="sidebar-container">
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className="toolbar-container">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <BreadcrumbsComponent />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              padding: "24px",
              justifyContent: "space-between",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
