import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import imagePlaceholder from "../../assets/image.png";
import userProfileImage from "../../assets/userprofile.png";
import { useLocation, Link } from "react-router-dom";

export default function BreadcrumbsComponent() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const [open, setOpen] = useState(false);
  const [client, setClient] = useState("");
  const [endDate, setEndDate] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClientChange = (event) => {
    setClient(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        px: 4,
        py: 2,
        borderBottom: 1,
        borderBottomColor: "#EFEFEF",
      }}
    >
      {/* Breadcrumbs with User Role and Profile */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          elevation={0}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              display: "flex",
              alignItems: "right",
              flexGrow: 1,
            }}
          >
            <Typography
              color="#272625"
              sx={{
                cursor: "pointer",
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                textDecoration: "none !importanat", // No underline by default
                "&:hover": {
                  textDecoration: "underline solid", // Show underline only on hover
                },
              }}
            >
              Home
            </Typography>
            {/* </Link> */}

            {pathnames.map((value, index) => {
              const isLast = index === pathnames.length - 1;
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`; // Build the route dynamically

              return isLast ? (
                <Typography
                  key={value}
                  color="#EF7540"
                  sx={{
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "24px",
                  }}
                >
                  {value.charAt(0).toUpperCase() + value.slice(1)}{" "}
                </Typography>
              ) : (
                // <Link
                //   key={value}
                //   to={routeTo}
                //   style={{ textDecoration: "none" }}
                // >
                <Typography
                  color="#272625"
                  sx={{
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {value.charAt(0).toUpperCase() + value.slice(1)}{" "}
                </Typography>
                // </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography
            color="#272625"
            sx={{
              fontWeight: 600,
              fontFamily: "Open Sans",
              fontSize: "20px",
              lineHeight: "17.68px",
            }}
          >
            User Role
          </Typography>
          <img
            src={userProfileImage}
            alt="User Profile"
            style={{ width: 40, height: 40, borderRadius: "33.89px" }}
          />
        </Box>
      </Box>

      {/* Accounts, Search Box, and +New Record Button */}
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontFamily: "Open Sans",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "30px",
            marginRight: "16px",
            color: "#333333",
          }}
        >
          Accounts
        </Typography>

        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          InputProps={{
            startAdornment: ( 
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: "#F5F5F5",
            borderRadius: "8px",
            width: "620px",
            height: "40px",
            padding: "8px, 16px, 8px, 16px",
            "& .MuiOutlinedInput-root": {
              height: "100%",
            },
            "& .MuiInputAdornment-root": {
              margin: 0,
            },
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            width: "262px",
            height: "40px",
            padding: "8px, 16px, 8px, 16px",
            borderRadius: "8px",
            backgroundColor: "#47A0DE",
            color: "#FFFFFF",
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "24px",
            gap: "10px",
          }}
        >
          + New Record
        </Button>
      </Box> */}

      {/* New Record Popup Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "785px",
            height: "665px",
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              Adding Employee
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {/* Profile Section */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <img
                src={imagePlaceholder}
                alt="Profile"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "8px",
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    color: "#333333",
                    paddingBottom: "4px",
                  }}
                >
                  Profile Photo
                </Typography>
                <Button
                  sx={{
                    fontFamily: "Nunito",
                    fontSize: "16px",
                    color: "#EF7540",
                    padding: "4px",
                  }}
                >
                  Upload
                </Button>
              </Box>
            </Box>

            {/* Client Name Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#333333",
                  paddingBottom: "8px",
                }}
              >
                Client Name
              </Typography>
              <Select
                value={client}
                onChange={handleClientChange}
                displayEmpty
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#767676",
                  padding: "2px",
                  borderRadius: "4px",
                  width: "300px",
                }}
              >
                <MenuItem value="" disabled>
                  Choose Client
                </MenuItem>
              </Select>
            </Box>
          </Box>

          {/* First Name and Last Name Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              marginBottom: "20px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#333333",
                  paddingBottom: "8px",
                }}
              >
                First Name
              </Typography>
              <TextField
                label="Enter your first name"
                variant="outlined"
                fullWidth
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#333333",
                }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#333333",
                  paddingBottom: "8px",
                }}
              >
                Last Name
              </Typography>
              <TextField
                label="Enter your last name"
                variant="outlined"
                fullWidth
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#333333",
                }}
              />
            </Box>
          </Box>

          {/* Mobile Number and End Date Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 2, flex: 1 }}
            >
              {/* Country Code Dropdown */}
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    color: "#333333",
                    paddingBottom: "8px",
                  }}
                >
                  Mobile Number
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Select
                    value="+1"
                    onChange={() => {}}
                    displayEmpty
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      color: "#767676",
                      borderRadius: "4px",
                      width: "120px",
                    }}
                  >
                    <MenuItem value="+1">+1</MenuItem>
                  </Select>
                  <TextField
                    label="Enter your mobile number"
                    variant="outlined"
                    fullWidth
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      color: "#333333",
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#333333",
                  paddingBottom: "8px",
                }}
              >
                End Date
              </Typography>
              <TextField
                type="date"
                label="Choose Date"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
                value={endDate}
                onChange={handleEndDateChange}
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#333333",
                  "& input": {
                    fontSize: "14px",
                  },
                  width: "100%",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              marginBottom: "20px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#333333",
                  paddingBottom: "8px",
                }}
              >
                Email
              </Typography>
              <TextField
                label="hayliefranci@gmail.com"
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#333333",
                }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#333333",
                  paddingBottom: "8px",
                }}
              >
                Password
              </Typography>
              <TextField
                type="password"
                label="Enter your password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  color: "#333333",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "142px",
                height: "45px",
                padding: "14px 40px",
                gap: "10px",
                borderRadius: "5px 0 0 0",
                opacity: "0.9",
                backgroundColor: "#47A0DE",
                "&:hover": {
                  backgroundColor: "#47A0DE",
                },
              }}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
