import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
} from "@mui/material";

export const FormInputText = ({
  name,
  control,
  label,
  type,
  placeholder,
  defaultValue,
  maxLength,
  className,
  disabled,
}) => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#E0E3E7",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
        borderColor: "#2D3843",
      }),
    },
  }));

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl variant="standard" className="custom-form-control">
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              className="text-input-label"
            >
              {label}
            </InputLabel>
            <BootstrapInput
              defaultValue={defaultValue || value}
              id="bootstrap-input"
              fullWidth
              // size="small"
              type={type || "text"}
              placeholder={placeholder}
              className={className}
              variant="outlined"
              onChange={onChange}
              maxLength={maxLength}
              error={error?.message ? true : false}
              margin="normal"
              disabled={disabled || false}
            />
            <FormHelperText style={{ color: "red" }}>
              {error?.message || ""}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
