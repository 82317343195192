import { InputAdornment, TextField } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";

function SearchBar({ setLoading, setRows, loadInitialData, searchAPI }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedTerm) {
      const fetchSearchResults = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get(
            `/${searchAPI}/${debouncedTerm}`
          );
          const formattedData = response.data.data.map((item, index) => ({
            index,
            ...item,
          }));
          setRows(formattedData);
        } catch (error) {
          console.error("Error fetching search results:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchSearchResults();
    } else {
      loadInitialData();
    }
  }, [debouncedTerm]);

  return (
    <TextField
      variant="outlined"
      placeholder="Search"
      onChange={(e) => setSearchTerm(e.target.value)}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlass size={24} />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "#F5F5F5",
        borderRadius: "8px",
        width: "75%",
        height: "40px",
        padding: "8px, 16px, 8px, 16px",
        "& .MuiOutlinedInput-root": {
          gap: 2,
          height: "100%",
          "& fieldset": {
            border: "none", // Removes the border
          },
        },
        "& .MuiInputAdornment-root": {
          margin: 0,
        },
      }}
    />
  );
}

export default SearchBar;
